<template>
  <v-container
    id="chaves"
    fluid
    tag="section"
  >
    Página não encontrada
  </v-container>
</template>

<script>
  export default {
    //
  }
</script>
